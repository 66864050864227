import { logError } from '@/utils'

export type Config = {
  SENTRY_DSN?: string
  SENTRY_ENV?: string
}

// eslint-disable-next-line import/no-mutable-exports
export let config: Config = {}

const getConfig = (): Promise<Config> =>
  fetch('/config.json', {
    method: 'GET',
  })
    .then(response => response.json())
    .catch(e => {
      logError('Fetch config error', e)
    })

export const initializeConfig = async () => {
  const cfg = await getConfig()
  config = {
    ...cfg,
  }
}
