import { AnyAction, Reducer } from '@reduxjs/toolkit'

import { UPDATE_BASKET } from './constants'
import { OrderState } from './types'

const init: OrderState = {
  basket: {
    total: '0',
    count: 0,
    products: [
      {
        id: 0,
        name: '0',
        quantity: 0,
        price: '0',
        total: '0',
      },
    ],
  },
}

export const orderReducer: Reducer<OrderState, AnyAction> = (
  state: OrderState = init,
  action: AnyAction
): OrderState => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_BASKET: {
      return { ...state, basket: payload }
    }

    default:
      return { ...state }
  }
}
