export const hexToRGBA = (hex: string, opacity: string): string => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

/**
 * Форматирует значение в строку с разделителями для тысяч и отображает отрицательные числа красным цветом.
 *
 * @param {string | number | null | undefined} value - Значение для форматирования. Может быть строкой, числом, null или undefined.
 * @param {boolean} [row=false] - Флаг, указывающий, возвращать ли значение как строку или как элемент JSX. Если true, возвращается строка, если false - элемент JSX.
 * @returns {string | JSX.Element} - Возвращает отформатированное значение как строку с разделителями для тысяч или как JSX-элемент (если значение отрицательное, цвет текста изменяется на красный).
 */
export const getFormattedValue = (
  value: string | null | undefined | number,
  row: boolean = false
): string | JSX.Element => {
  const isMinus = String(value).startsWith('-')
  if (value === undefined) return ''
  const stringValue = String(value)
  const parts = stringValue.split('.')
  const integerPartWithCommas = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (parts.length === 1) {
    return integerPartWithCommas
  }

  if (row) {
    return `${integerPartWithCommas},${parts[1]}`
  }

  return (
    <span style={{ color: isMinus ? '#FF4646' : '' }}>
      {integerPartWithCommas},{parts[1]}
    </span>
  )
}

export const formatPhone = (value: string): string => {
  const result = value.match(/(\d)(\d{3})(\d{3})(\d{2})(\d{2})/)
  return result ? `+${result![1]} (${result![2]}) ${result![3]}-${result![4]}-${result![5]}` : value
}

export const addCurrency = (value: number | string): string => `${value} ₸`

/**
 * Chunk array by 2 elements.
 * Could be reused with explicit divider count passing as an argument
 * @param array
 * @param divider
 */
export function chunkArray<T>(array: T[], divider = 2): T[][] {
  const result = []

  for (let i = 0; i < array.length; i += divider) {
    result.push(array.slice(i, i + divider))
  }

  return result
}
