import { AnyAction, Reducer } from '@reduxjs/toolkit'

import { ADD_ACTIVE_STEP, RESET_ACTIVE_STEPS, SET_SCREEN } from '@/store/sms/constants'

import { ScreenState, SmsState } from './types'

const init: SmsState = {
  activeSteps: [1],
  screen: ScreenState.CARD_LIST,
}

export const smsReducer: Reducer<SmsState, AnyAction> = (
  state: SmsState = init,
  action: AnyAction
): SmsState => {
  const { type, payload } = action
  switch (type) {
    case ADD_ACTIVE_STEP: {
      return { ...state, activeSteps: payload }
    }
    case RESET_ACTIVE_STEPS: {
      return { ...state, activeSteps: init.activeSteps }
    }
    case SET_SCREEN: {
      return { ...state, screen: payload }
    }
    default:
      return { ...state }
  }
}
