import * as Sentry from '@sentry/react'

import { config } from '@/config'

export const initSentry = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: config.SENTRY_ENV,
    integrations: [],
  })
}
