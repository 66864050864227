import * as Sentry from '@sentry/react';

export const logError = (name: string, err?: any) => {
  // eslint-disable-next-line no-console
  console.debug(name, err);

  Sentry.captureException(err, (scope) => {
    scope.setTransactionName(name);

    return scope;
  });
};
