import dayjs from 'dayjs'

export type PeriodType = 'hourly' | 'daily' | 'monthly' | 'yearly'

export enum DateTimeFormat {
  'DD.MM.YYYY' = 'DD.MM.YYYY',
  'DD.MM.YY' = 'DD.MM.YY',
  'YYYY.MM.DD' = 'YYYY.MM.DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'YYYY-MM-DDT' = 'YYYY-MM-DDT',
  'dddd D' = 'dddd D',
  'D MMMM' = 'D MMMM',
  'DD.MM.YYYY, HH:mm' = 'DD.MM.YYYY, HH:mm',
  'DD.MM.YYYY HH:mm' = 'DD.MM.YYYY HH:mm',
  'YYYY-MM-DDTHH:mm:ss.SSSZ' = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'HH:mm:ss.SSSZ' = 'HH:mm:ss.SSSZ',
  'HH:mm:ss.SSS' = 'HH:mm:ss.SSS',
  'HH:mm' = 'HH:mm',
}

export const getPeriod = ({
  date,
  shift,
  period,
  isShift,
}: {
  date: string[]
  shift: Array<number>
  period: PeriodType | ''
  isShift?: boolean
}) => {
  const formatShiftHour = (shiftHour: number): string =>
    shiftHour > 9 ? `${shiftHour}:00` : `0${shiftHour}:00`
  const startDate = dayjs(date[0], DateTimeFormat['YYYY.MM.DD'])
    .set('hour', shift[0])
    .format(DateTimeFormat['DD.MM.YYYY HH:mm'])
  let endDate =
    shift.length > 1
      ? dayjs(date[1], DateTimeFormat['YYYY.MM.DD'])
          .set('hour', shift[1])
          .format(DateTimeFormat['DD.MM.YYYY HH:mm'])
      : dayjs(date[1], DateTimeFormat['YYYY.MM.DD'])
          .set('hour', shift[0])
          .format(DateTimeFormat['DD.MM.YYYY HH:mm'])
  if (period === 'hourly' && isShift) {
    endDate = `${dayjs(date[1], DateTimeFormat['YYYY.MM.DD']).format(
      DateTimeFormat['DD.MM.YYYY']
    )} ${formatShiftHour(shift[0])}`
  }
  if (period === 'hourly' && !isShift) {
    endDate =
      shift[0] < shift[1]
        ? `${dayjs(date[1], DateTimeFormat['YYYY.MM.DD'])
            .subtract(1, 'day')
            .format(DateTimeFormat['DD.MM.YYYY'])} ${formatShiftHour(shift[1])}`
        : `${dayjs(date[1], DateTimeFormat['YYYY.MM.DD']).format(
            DateTimeFormat['DD.MM.YYYY']
          )} ${formatShiftHour(shift[0])}`
  }
  if (period === 'yearly' && isShift) {
    endDate = dayjs(date[1], DateTimeFormat['YYYY.MM.DD'])
      .add(1, 'year')
      .set('hour', shift[0])
      .format(DateTimeFormat['DD.MM.YYYY HH:mm'])
  }
  if (period === 'yearly' && !isShift) {
    endDate = dayjs(date[1], DateTimeFormat['YYYY.MM.DD'])
      .add(1, 'year')
      .set('hour', shift[1])
      .format(DateTimeFormat['DD.MM.YYYY HH:mm'])
  }
  return [...startDate.split(' '), ...endDate.split(' ')]
}
