import { configureStore } from '@reduxjs/toolkit'

// phone
import { phoneReducer } from '@/store/phone/reducer'
import { PhoneState } from '@/store/phone/types'
// sms
import { smsReducer } from '@/store/sms/reducer'
import { SmsState } from '@/store/sms/types'

// auth
import { authReducer } from './auth/reducer'
import { UserState } from './auth/types'
// halls
import { hallsReducer } from './halls/reducer'
import { HallsState } from './halls/types'
// menu
import { menuReducer } from './menu/reducer'
import { MenuState } from './menu/types'
// basket
import { orderReducer } from './order/reducer'
import { OrderState } from './order/types'

export interface RootState {
  auth: UserState
  halls: HallsState
  menu: MenuState
  order: OrderState
  phone: PhoneState
  sms: SmsState
}

const reducer = {
  auth: authReducer,
  halls: hallsReducer,
  menu: menuReducer,
  order: orderReducer,
  phone: phoneReducer,
  sms: smsReducer,
}

export const store = configureStore<RootState>({
  reducer,
})
