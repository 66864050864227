import { Suspense } from 'react'

import { ConfigProvider } from 'antd'
import ruRu from 'antd/es/locale/ru_RU'
import { RouterProvider } from 'react-router-dom'
import { router } from 'router'

import themeJSON from './ant_design_theme.json'

import './App.less'

const App = () => (
  <ConfigProvider locale={ruRu} theme={themeJSON}>
    <div className="App">
      <div className="font-face-gm">
        <Suspense fallback={<div className="SuspenseLoader" />}>
          <RouterProvider router={router} />
        </Suspense>
      </div>
    </div>
  </ConfigProvider>
)

export default App
