import React from 'react'

import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { config, initializeConfig } from '@/config'
import { logError } from '@/utils'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { initSentry } from './sentry'
import { store } from './store'

import './index.css'

const bootstrap = () => {
  const container = document.getElementById('root')!
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )

  reportWebVitals()
}

try {
  await initializeConfig()

  if (config.SENTRY_DSN) {
    initSentry()
  }

  bootstrap()
} catch (error) {
  logError('Initialization failed', error)
}
