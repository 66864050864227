import { Reducer, AnyAction } from '@reduxjs/toolkit'

import { parseLS } from '@/utils'

import { STORE_MENU } from './constants'
import { MenuState } from './types'

const init: MenuState = {
  ...parseLS('menu', { main: [], header: [] }),
}

export const menuReducer: Reducer<MenuState, AnyAction> = (
  state: MenuState = init,
  action: AnyAction
): MenuState => {
  const { type, payload } = action
  switch (type) {
    case STORE_MENU: {
      return { ...state, ...payload }
    }

    default:
      return { ...state }
  }
}
