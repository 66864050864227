import { Reducer, AnyAction } from '@reduxjs/toolkit'

import { clearLS, parseLS } from '@/utils'

import { LOGIN, LOGOUT } from './constants'
import { UserState } from './types'

const init: UserState = {
  ...parseLS('user', {}),
  isAdmin: false,
}

export const authReducer: Reducer<UserState, AnyAction> = (
  state: UserState = init,
  action: AnyAction
): UserState => {
  const { type, payload } = action
  switch (type) {
    case LOGIN: {
      return { ...state, ...payload }
    }

    case LOGOUT: {
      clearLS()
      document.cookie = 'bbp-cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      return { ...init }
    }

    default:
      return state
  }
}
