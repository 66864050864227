import { REGEX } from '@/utils/constants'

export const FORM_RULES = {
  REQUIRED: {
    required: true,
    message: 'Поле обязательно для заполнения',
    whitespace: true,
  },
  PHONE: {
    message: 'Номер телефона неправильный',
    pattern: REGEX.PHONE,
  },
  OLD_PHONE: {
    message: 'Номер телефона неправильный',
    pattern: REGEX.OLD_PHONE,
  },
  FULLNAME: {
    pattern: REGEX.FULLNAME,
    message: 'Введите корректное ФИО',
  },
  REASON_PHONE_CHANGE: {
    required: true,
    message: 'Укажите причину смены номера телефона',
  },
}
