import { AnyAction, Reducer } from '@reduxjs/toolkit'

import {
  CLOSE_STATUS_MODAL,
  OPEN_FORM_MODAL,
  OPEN_WARNING_MODAL,
  CLOSE_FORM_MODAL,
  CLOSE_WARNING_MODAL,
  SET_FORM_MODAL_STATUS,
  UPDATE_FORM_MODAL_DATA,
  UPDATE_REQUEST_ID,
  SET_CAN_USER_EDIT,
  SET_REQUEST_STATUS,
  UPDATE_STATUS_MODAL,
} from '@/store/phone/constants'

import { PhoneState } from './types'

const init: PhoneState = {
  modalVisibility: {
    form: false,
    status: false,
    warning: false,
  },
  formModalData: {
    old_phone: '',
    new_phone: '',
    reason: '',
    employee_phone: '',
    employee_name: '',
  },
  statusModalStatus: 'new',
  requestStatus: 'new',
  formModalStatus: 'create',
  canUserEdit: false,
  requestId: 0,
}

export const phoneReducer: Reducer<PhoneState, AnyAction> = (
  state: PhoneState = init,
  action: AnyAction
): PhoneState => {
  const { type, payload } = action
  switch (type) {
    case OPEN_FORM_MODAL: {
      return { ...state, modalVisibility: { ...state.modalVisibility, form: true } }
    }

    case OPEN_WARNING_MODAL: {
      return { ...state, modalVisibility: { ...state.modalVisibility, warning: true } }
    }

    case CLOSE_FORM_MODAL: {
      return { ...state, modalVisibility: { ...state.modalVisibility, form: false } }
    }

    case CLOSE_STATUS_MODAL: {
      return { ...state, modalVisibility: { ...state.modalVisibility, status: false } }
    }

    case CLOSE_WARNING_MODAL: {
      return { ...state, modalVisibility: { ...state.modalVisibility, warning: false } }
    }

    case UPDATE_FORM_MODAL_DATA: {
      return { ...state, formModalData: { ...state.formModalData, ...payload } }
    }

    case SET_FORM_MODAL_STATUS: {
      return { ...state, formModalStatus: payload }
    }

    case SET_CAN_USER_EDIT: {
      return { ...state, canUserEdit: payload }
    }

    case UPDATE_REQUEST_ID: {
      return { ...state, requestId: payload }
    }

    case SET_REQUEST_STATUS: {
      return { ...state, requestStatus: payload }
    }

    case UPDATE_STATUS_MODAL: {
      return {
        ...state,
        modalVisibility: { ...state.modalVisibility, status: true },
        statusModalStatus: payload,
      }
    }

    default:
      return { ...state }
  }
}
