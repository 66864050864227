export const OPEN_FORM_MODAL = 'OPEN_FORM_MODAL'

export const OPEN_WARNING_MODAL = 'OPEN_WARNING_MODAL'

export const CLOSE_FORM_MODAL = 'CLOSE_FORM_MODAL'

export const CLOSE_STATUS_MODAL = 'CLOSE_STATUS_MODAL'

export const CLOSE_WARNING_MODAL = 'CLOSE_WARNING_MODAL'

export const UPDATE_FORM_MODAL_DATA = 'UPDATE_FORM_MODAL_DATA'

export const SET_FORM_MODAL_STATUS = 'SET_FORM_MODAL_STATUS'

export const UPDATE_REQUEST_ID = 'UPDATE_REQUEST_ID'

export const SET_CAN_USER_EDIT = 'SET_CAN_USER_EDIT'

export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS'

export const UPDATE_STATUS_MODAL = 'UPDATE_STATUS_MODAL'
