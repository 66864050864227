import { AnyAction, Reducer } from 'redux'

import { parseLS } from '@/utils'

import { SET_ADDRESSES, SET_CURRENT_HALL, SET_HALLS_LIST } from './constants'
import { HallsState } from './types'

const init: HallsState = {
  hallsList: parseLS('hallsList', []),
  currentHall: parseLS('currentHall', 0),
  addresses: parseLS('addresses', []),
}

export const hallsReducer: Reducer<HallsState, AnyAction> = (
  state: HallsState = init,
  action: AnyAction
): HallsState => {
  const { type, payload } = action

  switch (type) {
    case SET_HALLS_LIST: {
      return { ...state, hallsList: payload }
    }

    case SET_CURRENT_HALL: {
      return {
        ...state,
        currentHall: payload,
      }
    }

    case SET_ADDRESSES: {
      return {
        ...state,
        addresses: payload,
      }
    }

    default:
      return state
  }
}
